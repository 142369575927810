<template>
  <div class="wrapper padding">
  </div>
</template>

<script>


import {setOfferState} from "@/api/staff";

export default {
  name: "staffInfo",
  data() {
    return {
      id: '',
      type: '',
    }
  },
  created() {
    this.id = this.$route.params.id
    this.type = Number(this.$route.params.type)
    this.state = Number(this.$route.params.state)
    this.setState()
  },
  components: {},
  methods: {
    async setState(){
      await setOfferState(this.id,this.state)
      if(this.state === 3){

        this.$alert('您已拒绝Offer Letter', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            window.location.replace("about:blank");
            window.close();
          }
        });
      }else{

        this.$alert('您已接受Offer Letter，请完善基本信息', '提示', {
          confirmButtonText: '完善信息',
          callback: () => {
            this.$router.replace(`/staffInfo/${this.id}/${this.type}`)
          }
        });



      }
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.wrapper {

  width: 100%;
  margin: 0 auto;

}
</style>
